import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/precencia/Hero"

const Presencia = ({ location }) => (
  <Layout location={location}>
    <SEO title="Presencia" />
    <Hero />
  </Layout>
)

export default Presencia